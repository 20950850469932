/**
 * # SvgCheckCircle
 *
 */
import * as React from 'react';

export function SvgCheckCircle(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" {...props}>
      <path
        fill="#3ee800"
        d="M32 0a32 32 0 1032 32A32.036 32.036 0 0032 0zm18.375 26.595l-20.1 20.1a4.526 4.526 0 01-6.4 0l-10.25-10.242a4.525 4.525 0 116.4-6.4l7.046 7.047 16.9-16.9a4.526 4.526 0 016.4 6.4z"
      />
    </svg>
  );
}

export function SvgEnvelope(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 70.005 64.171"
      {...props}
    >
      <path d="M69.87 13.274L45.316 37.816a14.61 14.61 0 01-20.628 0L.134 13.274c-.041.458-.134.878-.134 1.335v34.985a14.6 14.6 0 0014.584 14.577H55.42A14.6 14.6 0 0070 49.594V14.609c0-.457-.089-.877-.13-1.335z" />
      <path d="M41.191 33.743L67.83 7.046A14.536 14.536 0 0055.417 0h-40.83A14.536 14.536 0 002.174 7.046l26.639 26.7a8.757 8.757 0 0012.378 0z" />
    </svg>
  );
}

export function SvgTimeTraveler(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 64.014 64.014"
      {...props}
    >
      <path
        fill="#f4d1ae"
        d="M45.343 26.672a18.671 18.671 0 1018.671 18.671 18.671 18.671 0 00-18.671-18.671zm7.22 25.891a2.667 2.667 0 01-3.771 0l-5.334-5.334a2.667 2.667 0 01-.782-1.886v-8a2.667 2.667 0 115.334 0v6.9l4.553 4.553a2.667 2.667 0 010 3.767zM22.6 29.34a14.67 14.67 0 1114.67-14.67A14.67 14.67 0 0122.6 29.34zm7.687 34.674H2.667A2.667 2.667 0 010 61.317a27.406 27.406 0 0117.479-25.605 17.831 17.831 0 016.385-1.024 24.236 24.236 0 00-1.131 2.654 23.613 23.613 0 00-1.395 8 23.96 23.96 0 008.951 18.671z"
      />
      <path
        fill="#06bcc1"
        d="M45.343 26.672a18.671 18.671 0 1018.671 18.667 18.671 18.671 0 00-18.671-18.667zm7.22 25.891a2.667 2.667 0 01-3.771 0l-5.335-5.334a2.667 2.667 0 01-.781-1.89v-8a2.667 2.667 0 115.334 0v6.9l4.553 4.553a2.667 2.667 0 010 3.771zM30.289 64.014H2.667A2.667 2.667 0 010 61.317a27.406 27.406 0 0117.479-25.605 17.831 17.831 0 016.385-1.024 24.236 24.236 0 00-1.131 2.651 23.613 23.613 0 00-1.395 8 23.96 23.96 0 008.951 18.671z"
      />
      <path
        fill="#49306b"
        d="M45.343 26.672a18.671 18.671 0 1018.671 18.667 18.671 18.671 0 00-18.671-18.667zm7.22 25.891a2.667 2.667 0 01-3.771 0l-5.335-5.334a2.667 2.667 0 01-.781-1.89v-8a2.667 2.667 0 115.334 0v6.9l4.553 4.553a2.667 2.667 0 010 3.771zm-28.7-17.876c-.428.862-2.172-.048.001 0z"
      />
    </svg>
  );
}

import * as React from 'react';

export const SvgTimeMachineState1: React.FC = (props) => (
  <svg
    id="time-machine_State_1_No_Power_svg__time-machine_State_1_No_Power_svg__time-machine_State_1_No_Power"
    viewBox="0 0 340.69 565.92"
    {...props}
  >
    <defs>
      <linearGradient
        id="time-machine_State_1_No_Power_svg__time-machine_State_1_No_Power_svg__linear-gradient"
        x1={0.5}
        x2={0.5}
        y1={1}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0.26} stopColor="#ed1c24" />
        <stop offset={0.73} stopColor="#ed1c24" stopOpacity={0.702} />
        <stop offset={0.94} stopColor="#ed1c24" />
      </linearGradient>
      <clipPath id="time-machine_State_1_No_Power_svg__time-machine_State_1_No_Power_svg__clip-path">
        <path
          id="time-machine_State_1_No_Power_svg__time-machine_State_1_No_Power_svg__Path_1272"
          fill="none"
          d="M-253.524-4.608l50.14.883 36.923 274.625-121.264-1.264z"
          data-name="Path 1272"
          transform="translate(287.726 4.608)"
        />
      </clipPath>
    </defs>
    <g
      id="time-machine_State_1_No_Power_svg__time-machine_State_1_No_Power_svg__Time_Machine"
      data-name="Time Machine"
      transform="translate(2.663)"
    >
      <g
        id="time-machine_State_1_No_Power_svg__time-machine_State_1_No_Power_svg__BASE"
        transform="translate(0 255.342)"
      >
        <path
          id="time-machine_State_1_No_Power_svg__time-machine_State_1_No_Power_svg__Path_1196"
          fill="#333"
          d="M312.473 274.06H21.922L6.61 257.81h321.175z"
          data-name="Path 1196"
          transform="translate(2.209 -65.573)"
        />
        <g
          id="time-machine_State_1_No_Power_svg__time-machine_State_1_No_Power_svg__Left_Arm"
          data-name="Left Arm"
          transform="translate(8.819)"
        >
          <path
            id="time-machine_State_1_No_Power_svg__time-machine_State_1_No_Power_svg__Path_1197"
            fill="#b3b3b3"
            d="M47.737 159.8l7.413 2.049-31.041 97.845-7.9-2.917z"
            data-name="Path 1197"
            transform="translate(.456 -137.716)"
          />
          <path
            id="time-machine_State_1_No_Power_svg__time-machine_State_1_No_Power_svg__Path_1198"
            fill="gray"
            d="M209.951 256.24c-.156.677-29.305 92.412-29.305 92.412l-1.736-1.424 29.826-90.988z"
            data-name="Path 1198"
            transform="translate(-154.83 -230.597)"
          />
          <rect
            id="time-machine_State_1_No_Power_svg__time-machine_State_1_No_Power_svg__Rectangle_575"
            width={32.187}
            height={15.59}
            fill="#b3b3b3"
            data-name="Rectangle 575"
            rx={7.795}
            transform="translate(46.544 9.949)"
          />
          <circle
            id="time-machine_State_1_No_Power_svg__time-machine_State_1_No_Power_svg__Ellipse_285"
            cx={4.34}
            cy={4.34}
            r={4.34}
            fill="#4d4d4d"
            data-name="Ellipse 285"
            transform="translate(51.266 13.403)"
          />
          <path
            id="time-machine_State_1_No_Power_svg__time-machine_State_1_No_Power_svg__Path_1199"
            fill="#333"
            d="M194.67 253.786a5.989 5.989 0 002.5 2.049 3.993 3.993 0 002.969-.122 4.67 4.67 0 002.222-2.066 4.323 4.323 0 00.26-3.177 3.906 3.906 0 01.365 3.472 4.688 4.688 0 01-2.5 2.587 4.132 4.132 0 01-3.594 0 4.9 4.9 0 01-2.222-2.743z"
            data-name="Path 1199"
            transform="translate(-143.23 -234.844)"
          />
          <path
            id="time-machine_State_1_No_Power_svg__time-machine_State_1_No_Power_svg__Path_1200"
            fill="gray"
            d="M192.66 254.61l.347.4.764.712.521.382.851.469 1.007.382a7.777 7.777 0 001.545.26l16.823-.3v.868l-16.805-.347a9.371 9.371 0 01-1.545-.312l-.99-.417-.833-.5-.5-.4-.729-.747z"
            data-name="Path 1200"
            transform="translate(-144.71 -231.797)"
          />
          <path
            id="time-machine_State_1_No_Power_svg__time-machine_State_1_No_Power_svg__Rectangle_576"
            fill="#b3b3b3"
            d="M0 0h11.892v34.843H0z"
            data-name="Rectangle 576"
            transform="rotate(12.9 37.66 333.117)"
          />
          <path
            id="time-machine_State_1_No_Power_svg__time-machine_State_1_No_Power_svg__Path_1201"
            fill="gray"
            d="M45.5 166.989l11.6 2.656.139-.694-11.562-2.76z"
            data-name="Path 1201"
            transform="translate(22.016 -133.013)"
          />
          <rect
            id="time-machine_State_1_No_Power_svg__time-machine_State_1_No_Power_svg__Rectangle_577"
            width={30.624}
            height={64.686}
            fill="#b3b3b3"
            data-name="Rectangle 577"
            rx={15.312}
            transform="translate(0 113.836)"
          />
          <path
            id="time-machine_State_1_No_Power_svg__time-machine_State_1_No_Power_svg__Path_1202"
            fill="gray"
            d="M175.57 307.2l1.927.4 2.17.8 1.733 1c.868.677 1.736 1.372 2.621 2.083l1.823 2.257 1.3 2.431.451 1.18.312 1.059a14.859 14.859 0 01.382 2.535v17.417c0 3.3 0 6.614-.139 9.93l.313 5.434a30.4 30.4 0 01-.347 5l-.278 1.146a14.9 14.9 0 01-2.9 5.486 16.475 16.475 0 002.257-5.66l.174-1.181a38.653 38.653 0 000-4.913l-.139-4.965c0-3.316-.139-6.615-.139-9.93v-17.325a16.4 16.4 0 00-.278-2.378l-.243-1.059-.382-1.215v.087l-1.146-2.5v.087l-1.736-2.361c-.833-.747-1.632-1.51-2.448-2.292l-1.736-1.163-2.083-.99z"
            data-name="Path 1202"
            transform="translate(-157.289 -193.087)"
          />
          <circle
            id="time-machine_State_1_No_Power_svg__time-machine_State_1_No_Power_svg__Ellipse_286"
            cx={8.75}
            cy={8.75}
            r={8.75}
            fill="#4d4d4d"
            data-name="Ellipse 286"
            transform="translate(6.562 122.447)"
          />
          <path
            id="time-machine_State_1_No_Power_svg__time-machine_State_1_No_Power_svg__Path_1203"
            fill="#333"
            d="M168.822 317.206a9.115 9.115 0 002.917 5.746 8.541 8.541 0 005.972 2 8.958 8.958 0 005.851-2.378 9.219 9.219 0 002.743-5.851 8.49 8.49 0 01-2.274 6.337 9.392 9.392 0 01-6.215 2.83 8.976 8.976 0 01-6.441-2.361 8.49 8.49 0 01-2.552-6.319z"
            data-name="Path 1203"
            transform="translate(-162.259 -186.079)"
          />
        </g>
        <g
          id="time-machine_State_1_No_Power_svg__time-machine_State_1_No_Power_svg__Right_Arm"
          data-name="Right Arm"
          transform="translate(244.356 1.805)"
        >
          <path
            id="time-machine_State_1_No_Power_svg__time-machine_State_1_No_Power_svg__Path_1204"
            fill="#b3b3b3"
            d="M167.693 160.84l-7.413 2.031 31.041 97.846 7.9-2.9z"
            data-name="Path 1204"
            transform="translate(-129.033 -138.756)"
          />
          <path
            id="time-machine_State_1_No_Power_svg__time-machine_State_1_No_Power_svg__Path_1205"
            fill="gray"
            d="M319 257.28c.156.677 29.322 92.394 29.322 92.394l1.736-1.424-29.825-90.97z"
            data-name="Path 1205"
            transform="translate(-287.25 -231.637)"
          />
          <rect
            id="time-machine_State_1_No_Power_svg__time-machine_State_1_No_Power_svg__Rectangle_578"
            width={32.187}
            height={15.59}
            fill="#b3b3b3"
            data-name="Rectangle 578"
            rx={7.795}
            transform="rotate(180 20.145 12.77)"
          />
          <circle
            id="time-machine_State_1_No_Power_svg__time-machine_State_1_No_Power_svg__Ellipse_287"
            cx={4.34}
            cy={4.34}
            r={4.34}
            fill="#4d4d4d"
            data-name="Ellipse 287"
            transform="translate(26.906 13.404)"
          />
          <path
            id="time-machine_State_1_No_Power_svg__time-machine_State_1_No_Power_svg__Path_1206"
            fill="#333"
            d="M324.729 254.87a4.671 4.671 0 01-2.222 2.639 4.149 4.149 0 01-3.594 0 4.653 4.653 0 01-2.483-2.587 3.8 3.8 0 01.365-3.472 4.271 4.271 0 00.243 3.16 4.584 4.584 0 002.222 2.066 3.872 3.872 0 002.986.122 6.007 6.007 0 002.483-1.927z"
            data-name="Path 1206"
            transform="translate(-289.333 -235.928)"
          />
          <path
            id="time-machine_State_1_No_Power_svg__time-machine_State_1_No_Power_svg__Path_1207"
            fill="gray"
            d="M332.333 255.65l-.312.4-.746.747-.521.4-.816.5-.99.417a9.618 9.618 0 01-1.563.33l-16.805.347v-.8l16.805.3a7.813 7.813 0 001.528-.26l.99-.365.851-.469.538-.382.764-.712z"
            data-name="Path 1207"
            transform="translate(-293.448 -232.837)"
          />
          <path
            id="time-machine_State_1_No_Power_svg__time-machine_State_1_No_Power_svg__Rectangle_579"
            fill="#b3b3b3"
            d="M0 0h11.892v34.843H0z"
            data-name="Rectangle 579"
            transform="rotate(167.1 7.766 18.077)"
          />
          <path
            id="time-machine_State_1_No_Power_svg__time-machine_State_1_No_Power_svg__Path_1208"
            fill="gray"
            d="M158.4 168.036l-11.58 2.656-.16-.692 11.562-2.778z"
            data-name="Path 1208"
            transform="translate(-139.059 -134.06)"
          />
          <rect
            id="time-machine_State_1_No_Power_svg__time-machine_State_1_No_Power_svg__Rectangle_580"
            width={30.624}
            height={64.686}
            fill="#b3b3b3"
            data-name="Rectangle 580"
            rx={15.312}
            transform="rotate(180 43.254 89.252)"
          />
          <path
            id="time-machine_State_1_No_Power_svg__time-machine_State_1_No_Power_svg__Path_1209"
            fill="gray"
            d="M345.966 308.24l-1.875.642-2.066 1.007-1.736 1.18c-.8.781-1.615 1.545-2.431 2.292l-1.736 2.361v-.087l-1.163 2.483-.382 1.215-.243 1.059a23.522 23.522 0 00-.278 2.361V340.1c0 3.316 0 6.614-.139 9.93l-.156 4.965a35.681 35.681 0 000 4.913l.174 1.181a16.112 16.112 0 002.24 5.66 14.876 14.876 0 01-2.882-5.486l-.278-1.163a32.43 32.43 0 01-.347-5l-.139-4.965c0-3.316-.139-6.615-.139-9.93v-17.417a16.462 16.462 0 01.4-2.535l.3-1.042.451-1.181 1.3-2.413 1.736-2.257a76.02 76.02 0 012.622-2.083l1.736-1.007 2.153-.8z"
            data-name="Path 1209"
            transform="translate(-277.394 -194.126)"
          />
          <circle
            id="time-machine_State_1_No_Power_svg__time-machine_State_1_No_Power_svg__Ellipse_288"
            cx={8.75}
            cy={8.75}
            r={8.75}
            fill="#4d4d4d"
            data-name="Ellipse 288"
            transform="translate(62.808 122.429)"
          />
          <path
            id="time-machine_State_1_No_Power_svg__time-machine_State_1_No_Power_svg__Path_1210"
            fill="#333"
            d="M354.42 318.269a8.507 8.507 0 01-2.465 6.25 8.941 8.941 0 01-6.455 2.381 9.444 9.444 0 01-6.3-2.778 8.49 8.49 0 01-2.257-6.319 9.2 9.2 0 002.726 5.851 8.993 8.993 0 005.868 2.378 8.576 8.576 0 005.972-2 9.236 9.236 0 002.917-5.764z"
            data-name="Path 1210"
            transform="translate(-274.06 -187.089)"
          />
        </g>
        <path
          id="time-machine_State_1_No_Power_svg__time-machine_State_1_No_Power_svg__Rectangle_581"
          fill="#b3b3b3"
          d="M0 0h337.494v18.333H0z"
          data-name="Rectangle 581"
          transform="translate(0 173.904)"
        />
        <path
          id="time-machine_State_1_No_Power_svg__time-machine_State_1_No_Power_svg__Path_1211"
          fill="gray"
          d="M45.5 167.305l.347-1.545 11.6 2.778-.347 1.424z"
          data-name="Path 1211"
          transform="translate(30.836 -133.329)"
        />
      </g>
      <g
        id="time-machine_State_1_No_Power_svg__time-machine_State_1_No_Power_svg__Body"
        transform="translate(51.926 151.299)"
      >
        <path
          id="time-machine_State_1_No_Power_svg__time-machine_State_1_No_Power_svg__Shell"
          fill="#b3b3b3"
          d="M243.993 202.7l-2.917 14.583-7.917 35-29.512 141.252-11.665 65s150.5 20.366 215.919-.258l-25.993-150.522S364.963 220.2 359.7 199.979c-10.066-16.823-97.374-28.107-115.707 2.721z"
          transform="translate(-182.744 -180.222)"
        />
        <path
          id="time-machine_State_1_No_Power_svg__time-machine_State_1_No_Power_svg__Light_off"
          fill="#1e1e1e"
          d="M99.822 87.15h49.01l37.881 284.613H64.51z"
          data-name="Light off"
          transform="translate(-7.098 -87.15)"
        />
        <path
          id="time-machine_State_1_No_Power_svg__time-machine_State_1_No_Power_svg__Shadow_Left"
          fill="gray"
          d="M61.38 371.05l5.851-4.358L101.952 89.44l-4.6 2.214z"
          data-name="Shadow Left"
          transform="translate(-9.402 -85.464)"
        />
        <path
          id="time-machine_State_1_No_Power_svg__time-machine_State_1_No_Power_svg__Shadow_Right"
          fill="gray"
          d="M154.944 373.015l-4.844-2.4L113 89.6l4.93 1.059z"
          data-name="Shadow Right"
          transform="translate(28.595 -85.347)"
        />
        <path
          id="time-machine_State_1_No_Power_svg__time-machine_State_1_No_Power_svg__Ramp"
          fill="gray"
          d="M267.287 292.178H31.44l57.829-43.038 120.5 1.319z"
          transform="translate(-31.44 32.088)"
        />
        <g
          id="time-machine_State_1_No_Power_svg__time-machine_State_1_No_Power_svg__Door"
          transform="translate(57.923 7.207)"
        >
          <g
            id="time-machine_State_1_No_Power_svg__time-machine_State_1_No_Power_svg__Mask_Group_7"
            clipPath="url(#time-machine_State_1_No_Power_svg__time-machine_State_1_No_Power_svg__clip-path)"
            data-name="Mask Group 7"
          >
            <g
              id="time-machine_State_1_No_Power_svg__time-machine_State_1_No_Power_svg__Door_Parts"
              data-name="Door Parts"
              transform="translate(-.094 .536)"
            >
              <path
                id="time-machine_State_1_No_Power_svg__time-machine_State_1_No_Power_svg__Door-2"
                fill="#b3b3b3"
                d="M257.433 186h49.895l37.2 274.8-121.348-1.315zm55.711 72.586a19.947 19.947 0 00-19.878-19.878h-20.833a19.948 19.948 0 00-19.9 19.878 19.948 19.948 0 0019.9 19.878h20.833a19.947 19.947 0 0019.878-19.878z"
                data-name="Door"
                transform="translate(-223.18 -186)"
              />
              <g
                id="time-machine_State_1_No_Power_svg__time-machine_State_1_No_Power_svg__Door_Window"
                fill="gray"
                data-name="Door Window"
                transform="translate(29.374 52.707)"
              >
                <path
                  id="time-machine_State_1_No_Power_svg__time-machine_State_1_No_Power_svg__Path_1219"
                  d="M242.131 236.238a19.93 19.93 0 0119.878-19.878h-2.031a19.878 19.878 0 000 39.756h2.031a19.93 19.93 0 01-19.878-19.878z"
                  data-name="Path 1219"
                  transform="translate(-240.1 -216.36)"
                />
                <path
                  id="time-machine_State_1_No_Power_svg__time-machine_State_1_No_Power_svg__Path_1220"
                  d="M264.408 216.36h-2.048a19.878 19.878 0 110 39.756h2.049a19.878 19.878 0 100-39.756z"
                  data-name="Path 1220"
                  transform="translate(-223.715 -216.36)"
                />
              </g>
              <g
                id="time-machine_State_1_No_Power_svg__time-machine_State_1_No_Power_svg__Door_Handle"
                data-name="Door Handle"
                transform="translate(60.693 159.928)"
              >
                <rect
                  id="time-machine_State_1_No_Power_svg__time-machine_State_1_No_Power_svg__Rectangle_582"
                  width={15.069}
                  height={25.711}
                  fill="gray"
                  data-name="Rectangle 582"
                  rx={7.535}
                  transform="rotate(180 20.009 12.856)"
                />
                <circle
                  id="time-machine_State_1_No_Power_svg__time-machine_State_1_No_Power_svg__Ellipse_289"
                  cx={1.858}
                  cy={1.858}
                  r={1.858}
                  fill="#999"
                  data-name="Ellipse 289"
                  transform="translate(30.624 1.788)"
                />
                <circle
                  id="time-machine_State_1_No_Power_svg__time-machine_State_1_No_Power_svg__Ellipse_290"
                  cx={1.319}
                  cy={1.319}
                  r={1.319}
                  fill="#4d4d4d"
                  data-name="Ellipse 290"
                  transform="translate(31.163 2.326)"
                />
                <circle
                  id="time-machine_State_1_No_Power_svg__time-machine_State_1_No_Power_svg__Ellipse_291"
                  cx={1.858}
                  cy={1.858}
                  r={1.858}
                  fill="#999"
                  data-name="Ellipse 291"
                  transform="translate(30.624 20.277)"
                />
                <circle
                  id="time-machine_State_1_No_Power_svg__time-machine_State_1_No_Power_svg__Ellipse_292"
                  cx={1.319}
                  cy={1.319}
                  r={1.319}
                  fill="#4d4d4d"
                  data-name="Ellipse 292"
                  transform="translate(31.163 20.816)"
                />
                <path
                  id="time-machine_State_1_No_Power_svg__time-machine_State_1_No_Power_svg__Path_1221"
                  fill="#999"
                  d="M99.71 193.648l35.312 1.146v-5.1L99.71 190.94z"
                  data-name="Path 1221"
                  transform="translate(-99.71 -179.343)"
                />
                <ellipse
                  id="time-machine_State_1_No_Power_svg__time-machine_State_1_No_Power_svg__Ellipse_293"
                  cx={1.562}
                  cy={2.552}
                  fill="#999"
                  data-name="Ellipse 293"
                  rx={1.562}
                  ry={2.552}
                  transform="translate(33.749 10.347)"
                />
              </g>
            </g>
          </g>
        </g>
        <g
          id="time-machine_State_1_No_Power_svg__time-machine_State_1_No_Power_svg__Power_Meter"
          data-name="Power Meter"
          transform="translate(163.799 89.564)"
        >
          <path
            id="time-machine_State_1_No_Power_svg__time-machine_State_1_No_Power_svg__Path_1222"
            fill="#cdcdcd"
            d="M297.084 233.13a12.882 12.882 0 00-12.864 12.5.347.347 0 00.1.26.364.364 0 00.26.122h6.944a5.608 5.608 0 0011.215 0h6.944a.4.4 0 00.26-.122.348.348 0 00.1-.26 12.882 12.882 0 00-12.969-12.5z"
            data-name="Path 1222"
            transform="translate(-284.22 -233.13)"
          />
          <path
            id="time-machine_State_1_No_Power_svg__time-machine_State_1_No_Power_svg__Path_1223"
            fill="#bebebe"
            d="M291.09 246.071a5.385 5.385 0 00.937.087 5.608 5.608 0 005.608-5.608h-1.892a5.608 5.608 0 01-4.653 5.521z"
            data-name="Path 1223"
            transform="translate(-279.042 -227.668)"
          />
          <path
            id="time-machine_State_1_No_Power_svg__time-machine_State_1_No_Power_svg__Path_1224"
            fill="#bebebe"
            d="M304.527 246.012a.4.4 0 00.26-.122.348.348 0 00.1-.26 12.882 12.882 0 00-12.864-12.5h-.937a12.882 12.882 0 0111.927 12.847z"
            data-name="Path 1224"
            transform="translate(-279.043 -233.13)"
          />
          <path
            id="time-machine_State_1_No_Power_svg__time-machine_State_1_No_Power_svg__Path_1225"
            fill="#f76937"
            d="M300.758 241a.347.347 0 01-.33-.208 6.684 6.684 0 00-2.413-2.691 6.458 6.458 0 00-3.559-1.042 6.562 6.562 0 00-3.576 1.042 6.77 6.77 0 00-2.4 2.691.382.382 0 01-.677-.33 7.391 7.391 0 0113.3 0 .382.382 0 01-.174.5.312.312 0 01-.174.035z"
            data-name="Path 1225"
            transform="translate(-281.591 -230.805)"
          />
          <path
            id="time-machine_State_1_No_Power_svg__time-machine_State_1_No_Power_svg__Path_1226"
            fill="#40c1fa"
            d="M.124.014a.1.1 0 00-.1 0 .1.1 0 000 .122l6.94 3.142.677-2.4z"
            data-name="Path 1226"
            transform="rotate(19.01 -21.128 21.327)"
          />
          <path
            id="time-machine_State_1_No_Power_svg__time-machine_State_1_No_Power_svg__Path_1227"
            fill="#ffd508"
            d="M297.459 242.28a4.063 4.063 0 11-1.184-2.884 4.08 4.08 0 011.184 2.884z"
            data-name="Path 1227"
            transform="translate(-280.48 -229.398)"
          />
          <path
            id="time-machine_State_1_No_Power_svg__time-machine_State_1_No_Power_svg__Path_1228"
            fill="#eec707"
            d="M296.076 242.28a4.08 4.08 0 00-4.062-4.08 4.235 4.235 0 00-.833.087 4.08 4.08 0 010 7.969 4.234 4.234 0 00.833 0 4.063 4.063 0 004.062-3.977z"
            data-name="Path 1228"
            transform="translate(-279.097 -229.398)"
          />
          <path
            id="time-machine_State_1_No_Power_svg__time-machine_State_1_No_Power_svg__Path_1229"
            fill="#f76937"
            d="M293.417 239.145h-1.441a.122.122 0 00-.1 0l-1.267 2.326a.139.139 0 00.122.191h1.181a.122.122 0 01.1.174l-.625 1.528a.125.125 0 10.208.139l2.465-2.465a.139.139 0 000-.226h-1.233a.121.121 0 01-.1-.191l.885-1.337a.139.139 0 00-.191-.139z"
            data-name="Path 1229"
            transform="translate(-279.528 -228.711)"
          />
          <path
            id="time-machine_State_1_No_Power_svg__time-machine_State_1_No_Power_svg__Path_1230"
            fill="#5a5a5a"
            d="M291.792 236.5a.382.382 0 01-.382-.382V234.8a.382.382 0 01.764 0v1.319a.382.382 0 01-.382.381z"
            data-name="Path 1230"
            transform="translate(-278.927 -232.18)"
          />
          <path
            id="time-machine_State_1_No_Power_svg__time-machine_State_1_No_Power_svg__Path_1231"
            fill="#5a5a5a"
            d="M295.431 237.85a.347.347 0 01-.278-.122.382.382 0 010-.521l.938-.937a.365.365 0 01.538 0 .4.4 0 010 .538l-.937.92a.33.33 0 01-.26.122z"
            data-name="Path 1231"
            transform="translate(-276.248 -230.905)"
          />
          <path
            id="time-machine_State_1_No_Power_svg__time-machine_State_1_No_Power_svg__Path_1232"
            fill="#5a5a5a"
            d="M288.546 237.838a.313.313 0 01-.26-.122l-.938-.92a.382.382 0 01.538-.538l.92.937a.347.347 0 010 .521.312.312 0 01-.26.122z"
            data-name="Path 1232"
            transform="translate(-281.983 -230.893)"
          />
        </g>
      </g>
      <g
        id="time-machine_State_1_No_Power_svg__time-machine_State_1_No_Power_svg__Top"
        transform="translate(99.408)"
      >
        <g
          id="time-machine_State_1_No_Power_svg__time-machine_State_1_No_Power_svg__Blue_Cord"
          data-name="Blue Cord"
          transform="translate(8.798 80.977)"
        >
          <path
            id="time-machine_State_1_No_Power_svg__time-machine_State_1_No_Power_svg__Path_1233"
            fill="none"
            stroke="#0071bc"
            strokeMiterlimit={10}
            strokeWidth={5}
            d="M19.187 9.734a58.89 58.89 0 00-8.333-8.68C-.5-4.154-1.437 11.47 1.271 14.943c2.413 3.16 12.6 18.333 12.6 18.333"
            data-name="Path 1233"
          />
          <rect
            id="time-machine_State_1_No_Power_svg__time-machine_State_1_No_Power_svg__Rectangle_583"
            width={10.781}
            height={17.326}
            fill="#b3b3b3"
            data-name="Rectangle 583"
            rx={5.391}
            transform="rotate(-35.7 23.856 -15.384)"
          />
        </g>
        <g
          id="time-machine_State_1_No_Power_svg__time-machine_State_1_No_Power_svg__Greem_Cord"
          data-name="Greem Cord"
          transform="translate(104.2 76.598)"
        >
          <path
            id="time-machine_State_1_No_Power_svg__time-machine_State_1_No_Power_svg__Path_1234"
            fill="none"
            stroke="#39b54a"
            strokeMiterlimit={10}
            strokeWidth={5}
            d="M18.09 28.019c.382.608 11.927-11.753 12.482-20.121.625-12.5-15.989-7.361-18.264-3.472C10.572 6.162-.001 22.95-.001 22.95"
            data-name="Path 1234"
          />
          <rect
            id="time-machine_State_1_No_Power_svg__time-machine_State_1_No_Power_svg__Rectangle_584"
            width={10.781}
            height={20.312}
            fill="#b3b3b3"
            data-name="Rectangle 584"
            rx={5.391}
            transform="rotate(42.37 -18.03 32.573)"
          />
        </g>
        <g
          id="time-machine_State_1_No_Power_svg__time-machine_State_1_No_Power_svg__Red_Cord"
          data-name="Red Cord"
          transform="translate(62.044 51.575)"
        >
          <path
            id="time-machine_State_1_No_Power_svg__time-machine_State_1_No_Power_svg__Path_1235"
            fill="none"
            stroke="#ed1c24"
            strokeMiterlimit={10}
            strokeWidth={5}
            d="M24.065 25.502s1.163-7.917 1.354-12.691C26.044-9.515.519.658.003 18.019-.119 20.588 2.92 41.265 2.92 41.265"
            data-name="Path 1235"
          />
          <rect
            id="time-machine_State_1_No_Power_svg__time-machine_State_1_No_Power_svg__Rectangle_585"
            width={10.781}
            height={20.468}
            fill="#b3b3b3"
            data-name="Rectangle 585"
            rx={5.391}
            transform="rotate(10.92 -90.826 110.35)"
          />
        </g>
        <g
          id="time-machine_State_1_No_Power_svg__time-machine_State_1_No_Power_svg__Antenna"
          transform="translate(26.163 45.659)"
        >
          <circle
            id="time-machine_State_1_No_Power_svg__time-machine_State_1_No_Power_svg__Ellipse_294"
            cx={6.059}
            cy={6.059}
            r={6.059}
            fill="#b3b3b3"
            data-name="Ellipse 294"
          />
          <path
            id="time-machine_State_1_No_Power_svg__time-machine_State_1_No_Power_svg__Path_1236"
            fill="#4d4d4d"
            d="M234.94 128.865a4.913 4.913 0 016.51-2.361l15.156 32.447-13.732 4.983z"
            data-name="Path 1236"
            transform="translate(-230.339 -116.747)"
          />
          <path
            id="time-machine_State_1_No_Power_svg__time-machine_State_1_No_Power_svg__Path_1237"
            fill="#b3b3b3"
            d="M239.766 149a13.455 13.455 0 0113.541-5.087c3.472-.434 2.6-5.555-2.483-5.208-4.236-.417-8.68 1.84-12.378 4.635-3.281 2.375-1.979 6.333 1.32 5.66z"
            data-name="Path 1237"
            transform="translate(-229.159 -107.469)"
          />
          <path
            id="time-machine_State_1_No_Power_svg__time-machine_State_1_No_Power_svg__Path_1238"
            fill="#b3b3b3"
            d="M238.088 141.281a10.625 10.625 0 0110.92-4.1c2.882-.365 2.153-4.583-2.049-4.34a14.7 14.7 0 00-9.965 3.646c-3.246 2.086-1.632 5.419 1.094 4.794z"
            data-name="Path 1238"
            transform="translate(-230.154 -111.751)"
          />
          <path
            id="time-machine_State_1_No_Power_svg__time-machine_State_1_No_Power_svg__Path_1239"
            fill="#b3b3b3"
            d="M236.59 134.678a9.288 9.288 0 019.01-3.3c2.153-.712 1.6-3.09-1.372-2.986a12.153 12.153 0 00-8.455 2.639c-2.898 1.91-1.353 4.41.817 3.647z"
            data-name="Path 1239"
            transform="translate(-230.896 -115.042)"
          />
        </g>
        <g
          id="time-machine_State_1_No_Power_svg__time-machine_State_1_No_Power_svg__Antenna-2"
          data-name="Antenna"
          transform="translate(89.009 46.128)"
        >
          <circle
            id="time-machine_State_1_No_Power_svg__time-machine_State_1_No_Power_svg__Ellipse_295"
            cx={6.059}
            cy={6.059}
            r={6.059}
            fill="#b3b3b3"
            data-name="Ellipse 295"
            transform="translate(14.93)"
          />
          <path
            id="time-machine_State_1_No_Power_svg__time-machine_State_1_No_Power_svg__Path_1240"
            fill="#4d4d4d"
            d="M284.514 126.836a4.913 4.913 0 016.545 2.274l-8.68 34.722-13.889-4.792z"
            data-name="Path 1240"
            transform="translate(-268.49 -116.993)"
          />
          <path
            id="time-machine_State_1_No_Power_svg__time-machine_State_1_No_Power_svg__Path_1241"
            fill="#b3b3b3"
            d="M270.388 144.243a13.4 13.4 0 0113.715 4.6c2.986 1.858 5.521-2.674 1.389-5.66-3.021-3-7.882-4.045-12.517-4.219-4.097-.264-5.575 3.699-2.587 5.279z"
            data-name="Path 1241"
            transform="translate(-268.253 -107.716)"
          />
          <path
            id="time-machine_State_1_No_Power_svg__time-machine_State_1_No_Power_svg__Path_1242"
            fill="#b3b3b3"
            d="M273.481 137.527a10.66 10.66 0 0111.076 3.7c2.465 1.545 4.548-2.2 1.128-4.67a14.774 14.774 0 00-10.017-3.472c-3.836-.401-4.687 3.192-2.187 4.442z"
            data-name="Path 1242"
            transform="translate(-265.86 -112.059)"
          />
          <path
            id="time-machine_State_1_No_Power_svg__time-machine_State_1_No_Power_svg__Path_1243"
            fill="#b3b3b3"
            d="M276.079 131.79a9.253 9.253 0 019.062 3.125c2.135.8 3.194-1.389.833-3.177a12.152 12.152 0 00-8.229-3.3c-3.471-.311-3.871 2.623-1.666 3.352z"
            data-name="Path 1243"
            transform="translate(-263.909 -115.471)"
          />
        </g>
        <path
          id="time-machine_State_1_No_Power_svg__time-machine_State_1_No_Power_svg__Dome"
          fill="#b3b3b3"
          d="M223.816 208.1l120.571 1.736c-2.031-15.312-3.993-29.774-21.857-50.971-17.969-22.569-59.634-20.364-76.092-2.517-22.829 22.361-23.42 49.687-22.777 52.673"
          transform="translate(-212.584 -59.01)"
        />
        <g
          id="time-machine_State_1_No_Power_svg__time-machine_State_1_No_Power_svg__Left"
          transform="translate(5.62 140.952)"
        >
          <path
            id="time-machine_State_1_No_Power_svg__time-machine_State_1_No_Power_svg__Path_1244"
            fill="#333"
            d="M227.563 213.726a9.653 9.653 0 01-6.319-5.208c-1.632-4.062-.4-6.944.781-9.844 6.944-10.851 22.934-15.955 33.819-15.955l1.749 3.429c-10.278 3.472-24.041 10.686-27.53 17.179z"
            data-name="Path 1244"
            transform="translate(-220.457 -170.324)"
          />
          <path
            id="time-machine_State_1_No_Power_svg__time-machine_State_1_No_Power_svg__Path_1245"
            fill="#4d4d4d"
            d="M220.46 209.295v-17a4.913 4.913 0 01.625-2.43c5.5-9.288 15.5-12.569 31.371-14.288a38.872 38.872 0 003.472 12.517c-15.243.368-30.628 9.048-35.468 21.201z"
            data-name="Path 1245"
            transform="translate(-220.455 -175.58)"
          />
        </g>
        <g
          id="time-machine_State_1_No_Power_svg__time-machine_State_1_No_Power_svg__Right"
          transform="translate(97.555 138.609)"
        >
          <path
            id="time-machine_State_1_No_Power_svg__time-machine_State_1_No_Power_svg__Path_1246"
            fill="#333"
            d="M307.179 211.147l-2.879-12.014c-6.25-6.3-21.853-11.558-31.922-13.589l2.926-4.733c15.833 2.639 32.972 10.767 37.329 20.874-.506 5.521-2.451 8.437-5.454 9.462z"
            data-name="Path 1246"
            transform="translate(-272.375 -166.738)"
          />
          <path
            id="time-machine_State_1_No_Power_svg__time-machine_State_1_No_Power_svg__Path_1247"
            fill="#4d4d4d"
            d="M278.449 174.23c-.59 5.486-1.48 10.329-3.737 14.183 13.678 2.023 32.325 10.005 37.263 20.771a108.43 108.43 0 00-.211-16.378 2.43 2.43 0 00-.417-1.2c-6.163-8.748-21.735-15.796-32.898-17.376z"
            data-name="Path 1247"
            transform="translate(-271.7 -174.23)"
          />
        </g>
        <g
          id="time-machine_State_1_No_Power_svg__time-machine_State_1_No_Power_svg__Clock"
          transform="translate(37.291 104.095)"
        >
          <circle
            id="time-machine_State_1_No_Power_svg__time-machine_State_1_No_Power_svg__Ellipse_296"
            cx={33.506}
            cy={33.506}
            r={33.506}
            fill="#4d4d4d"
            data-name="Ellipse 296"
          />
          <path
            id="time-machine_State_1_No_Power_svg__time-machine_State_1_No_Power_svg__Path_1248"
            fill="#333"
            d="M304.144 178.26a41.786 41.786 0 01-4.236 10.052 32.253 32.253 0 01-7.17 8.333 32.986 32.986 0 01-20.625 7.2 36.544 36.544 0 01-20.833-6.528 30.728 30.728 0 01-7.743-7.83 25.85 25.85 0 01-4.028-10.121 29.132 29.132 0 0012.517 16.857 36.458 36.458 0 0020.139 5.885 32.326 32.326 0 0019.791-6.562 38.4 38.4 0 0012.187-17.291z"
            data-name="Path 1248"
            transform="translate(-238.103 -136.75)"
          />
          <circle
            id="time-machine_State_1_No_Power_svg__time-machine_State_1_No_Power_svg__Ellipse_297"
            cx={26.371}
            cy={26.371}
            r={26.371}
            fill="#fff"
            data-name="Ellipse 297"
            transform="translate(7.135 7.135)"
          />
          <path
            id="time-machine_State_1_No_Power_svg__time-machine_State_1_No_Power_svg__Path_1249"
            fill="#333"
            d="M244 179.57a31.37 31.37 0 009.236 10.989 24.826 24.826 0 0013.264 4.722 26.041 26.041 0 0013.889-3 30.277 30.277 0 0010.712-9.566 26.961 26.961 0 01-10.226 10.416 26.041 26.041 0 01-14.357 3.472 25.19 25.19 0 01-13.889-5.208A28.055 28.055 0 01244 179.57z"
            data-name="Path 1249"
            transform="translate(-234.799 -135.786)"
          />
          <path
            id="time-machine_State_1_No_Power_svg__time-machine_State_1_No_Power_svg__Path_1250"
            fill="#333"
            d="M244.43 173.12a25.5 25.5 0 0122.795-14.878 27.378 27.378 0 0113.889 3.472c1.024.625 2 1.337 3 2l2.674 2.361 2.274 2.743a94.546 94.546 0 011.84 3.038 26.805 26.805 0 00-10.26-9.253 27.637 27.637 0 00-13.333-3.055 26.041 26.041 0 00-13.107 3.75 28.941 28.941 0 00-9.774 9.826z"
            data-name="Path 1250"
            transform="translate(-234.482 -151.489)"
          />
          <path
            id="time-machine_State_1_No_Power_svg__time-machine_State_1_No_Power_svg__Path_1251"
            fill="#4d4d4d"
            d="M258.4 166.227a.937.937 0 00.938-.937v-3.79a.946.946 0 10-1.892 0v3.785a.955.955 0 00.954.942z"
            data-name="Path 1251"
            transform="translate(-224.898 -149.786)"
          />
          <path
            id="time-machine_State_1_No_Power_svg__time-machine_State_1_No_Power_svg__Path_1252"
            fill="#4d4d4d"
            d="M258.4 183.45a.955.955 0 00-.955.938v3.784a.955.955 0 00.955.955.937.937 0 00.938-.955V184.4a.938.938 0 00-.938-.955z"
            data-name="Path 1252"
            transform="translate(-224.898 -132.93)"
          />
          <path
            id="time-machine_State_1_No_Power_svg__time-machine_State_1_No_Power_svg__Path_1253"
            fill="#4d4d4d"
            d="M252.946 162.713a.92.92 0 00-1.285-.347.937.937 0 00-.347 1.285l.938 1.649a.972.972 0 00.816.469.99.99 0 00.486-.139.938.938 0 00.347-1.285z"
            data-name="Path 1253"
            transform="translate(-229.508 -148.547)"
          />
          <path
            id="time-machine_State_1_No_Power_svg__time-machine_State_1_No_Power_svg__Path_1254"
            fill="#4d4d4d"
            d="M264.935 183.48a.941.941 0 00-1.632.937l.955 1.736a.945.945 0 101.632-.955z"
            data-name="Path 1254"
            transform="translate(-220.682 -133.255)"
          />
          <path
            id="time-machine_State_1_No_Power_svg__time-machine_State_1_No_Power_svg__Path_1255"
            fill="#4d4d4d"
            d="M249.695 167.893l-1.736-.937a.938.938 0 00-1.285.33.955.955 0 00.347 1.3l1.632.937a.972.972 0 00.469.139.955.955 0 00.816-.469.937.937 0 00-.243-1.3z"
            data-name="Path 1255"
            transform="translate(-232.924 -145.168)"
          />
          <path
            id="time-machine_State_1_No_Power_svg__time-machine_State_1_No_Power_svg__Path_1256"
            fill="#4d4d4d"
            d="M270.428 179.882l-1.632-.937a.955.955 0 00-1.3.347.937.937 0 00.347 1.285l1.632.955a.955.955 0 00.955-1.649z"
            data-name="Path 1256"
            transform="translate(-217.599 -136.341)"
          />
          <path
            id="time-machine_State_1_No_Power_svg__time-machine_State_1_No_Power_svg__Path_1257"
            fill="#4d4d4d"
            d="M250.6 174.045a.955.955 0 00-.955-.955h-3.785a.946.946 0 100 1.892h3.785a.955.955 0 00.955-.938z"
            data-name="Path 1257"
            transform="translate(-234.129 -140.556)"
          />
          <path
            id="time-machine_State_1_No_Power_svg__time-machine_State_1_No_Power_svg__Path_1258"
            fill="#4d4d4d"
            d="M272.55 173.09h-3.785a.955.955 0 00-.955.955.938.938 0 00.955.938h3.785a.937.937 0 00.937-.937.955.955 0 00-.938-.955z"
            data-name="Path 1258"
            transform="translate(-217.273 -140.556)"
          />
          <path
            id="time-machine_State_1_No_Power_svg__time-machine_State_1_No_Power_svg__Path_1259"
            fill="#4d4d4d"
            d="M248.7 178.958l-1.632.937a.948.948 0 00.469 1.771.9.9 0 00.469-.122l1.736-.955a.937.937 0 00.347-1.285.972.972 0 00-1.389-.347z"
            data-name="Path 1259"
            transform="translate(-232.888 -136.355)"
          />
          <path
            id="time-machine_State_1_No_Power_svg__time-machine_State_1_No_Power_svg__Path_1260"
            fill="#4d4d4d"
            d="M268.31 169.663a.99.99 0 00.486-.139l1.632-.937a.945.945 0 10-.955-1.632l-1.632.938a.955.955 0 00-.347 1.3.972.972 0 00.816.469z"
            data-name="Path 1260"
            transform="translate(-217.599 -145.167)"
          />
          <path
            id="time-machine_State_1_No_Power_svg__time-machine_State_1_No_Power_svg__Path_1261"
            fill="#4d4d4d"
            d="M253.554 183.134a.955.955 0 00-1.3.347l-.937 1.632a.955.955 0 00.347 1.3.9.9 0 00.469.122.955.955 0 00.816-.469l.955-1.736a.937.937 0 00-.347-1.2z"
            data-name="Path 1261"
            transform="translate(-229.509 -133.257)"
          />
          <path
            id="time-machine_State_1_No_Power_svg__time-machine_State_1_No_Power_svg__Path_1262"
            fill="#4d4d4d"
            d="M265.543 162.363a.937.937 0 00-1.285.347l-.955 1.632a.937.937 0 00.347 1.285.972.972 0 00.469.139.955.955 0 00.816-.469l.955-1.649a.937.937 0 00-.347-1.285z"
            data-name="Path 1262"
            transform="translate(-220.683 -148.544)"
          />
          <path
            id="time-machine_State_1_No_Power_svg__time-machine_State_1_No_Power_svg__Line_5"
            fill="none"
            stroke="#4d4d4d"
            strokeLinecap="round"
            strokeMiterlimit={10}
            strokeWidth={2}
            d="M31.527 31.51l9.618 9.635"
            data-name="Line 5"
          />
          <path
            id="time-machine_State_1_No_Power_svg__time-machine_State_1_No_Power_svg__Path_1271"
            fill="none"
            stroke="#4d4d4d"
            strokeLinecap="round"
            strokeWidth={2}
            d="M33.506 19.27v19.218"
            data-name="Path 1271"
          />
          <circle
            id="time-machine_State_1_No_Power_svg__time-machine_State_1_No_Power_svg__Ellipse_298"
            cx={1.823}
            cy={1.823}
            r={1.823}
            fill="#4d4d4d"
            data-name="Ellipse 298"
            transform="translate(31.683 31.666)"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default SvgTimeMachineState1;
